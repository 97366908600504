import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, InputLabel, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connectSearchBox } from 'react-instantsearch-dom';

import { SearchInputProps } from './models';

const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: '240px',

  input: {
    height: '35px',
    padding: '0 15px',
    fontSize: '1.4rem',
  },

  svg: {
    width: '18px',
    height: '18px',
    color: theme.palette.secondary.main,
  },
}));

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.info.dark,
  marginBottom: '5px',
}));

const TextFieldContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  padding: '0 10px',
}));

const SearchInput: FC<SearchInputProps> = ({ refine }) => {
  const { t } = useTranslation();

  return (
    <TextFieldContainer>
      <div>
        <StyledLabel variant="standard" id="search-input">
          {t('search')}
        </StyledLabel>
        <StyledTextField
          id="search-input"
          placeholder="By keyword"
          onKeyPress={(e: React.KeyboardEvent) => {
            const target = e.target as HTMLInputElement;
            if (e.key === 'Enter') {
              refine(target.value);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </TextFieldContainer>
  );
};

export default connectSearchBox(SearchInput);
