import { styled } from '@mui/system';
import classNames from 'classnames';
import { FC } from 'react';

import { PredictionProps } from './models';

const Span = styled('span')(({ theme }) => ({
  fontWeight: 'bold',

  '&.NotAdverseEvent': {
    color: theme.palette.success.main,
  },

  '&.overrided': {
    textDecoration: 'line-through',
    display: 'block',
  },
}));

export const Prediction: FC<PredictionProps> = ({
  value: [prediction, validation],
}) => {
  const overrided = validation && validation !== prediction;

  return (
    <>
      <Span
        className={classNames(prediction.replace(/\s/g, ''), {
          overrided,
        })}
      >
        {prediction}
      </Span>
      {overrided ? (
        <Span className={validation.replace(/\s/g, '')}>{validation}</Span>
      ) : null}
    </>
  );
};
