import { createTheme } from '@mui/material/styles';
import { Theme } from 'contexts/ThemeContext/models';

const theme = createTheme(
  {
    palette: {
      background: {
        default: '#F5F6F8',
        paper: '#FFFFFF',
      },
      primary: {
        dark: '#42596C',
        main: '#D9DEE1',
        light: '#F7F8FA',
      },
      secondary: {
        main: '#FC007F',
        light: '#EEB72B',
        dark: '#0554A9',
      },
      action: {
        active: '#20B1EF',
      },
      text: {
        primary: '#42596C',
        secondary: '#FC007F',
      },
      success: {
        main: '#55B82A',
      },
      warning: {
        main: '#ED8A2C',
      },
      info: {
        main: '#757575',
        dark: 'black',
        light: '#faaf00',
      },
    },
    typography: {
      htmlFontSize: 10,
      fontFamily: 'Energy',
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: 'white',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltipPlacementBottom: {
            background: '#42596C',
          },
          arrow: {
            color: '#42596C',
          },
        },
      },
    },
  },
  {
    sizes: {
      headerHeight: 228,
    },
    customColors: {
      tag: '#D7E4F1',
    },
  }
) as unknown as Theme;

export default theme;
