/* eslint-disable no-console */
import algoliasearch from 'algoliasearch';
import { handleApiRequest } from 'helpers/apiHandler';
import { handleError } from 'helpers/errorHandler';
import useAuth from 'hooks/useAuth';
import useTable from 'hooks/useTable';
import { useCallback } from 'react';
import { Review } from 'types/algolia';
import { ActionType } from 'types/tableConfig';

import {
  AssignUsersResult,
  ReviewCommentProps,
  ReviewCommentResult,
  UseReview,
} from './models';

const useReview: UseReview = () => {
  const { updateLocalState, localStates } = useTable();
  const { currentUser } = useAuth();

  const getReview = useCallback(async (objectID: string) => {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID as string,
      process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
    );
    const index = client.initIndex(
      process.env.REACT_APP_ALGOLIA_INDEX as string
    );

    const reviews = await index.getObjects<Review>([objectID]);

    if (reviews.results[0]) {
      return reviews.results[0];
    }

    throw new Error('Cannot find given object');
  }, []);

  const assignedToCurrentUserIfNotExists = (reviewRaw: Review) => {
    const userEmail = currentUser?.preferred_username as string;
    const assignedUsers =
      (
        (localStates[reviewRaw.objectID]?.assigned as string) ||
        reviewRaw.assigned
      )
        ?.split(',')
        .map((val) => val.trim())
        .filter((val) => val?.length) || [];

    if (!assignedUsers.includes(userEmail)) {
      return assignedUsers.concat([userEmail]).join(', ');
    }

    return assignedUsers.join(', ');
  };

  const reviewComment = async ({
    objectId,
    reviewId,
    ...review
  }: ReviewCommentProps): Promise<ReviewCommentResult> =>
    handleApiRequest<ReviewCommentResult>(`review/${objectId}`, 'POST', {
      ...review,
      id: reviewId,
    });

  const reviewIssue = useCallback(
    async (
      objectId: string,
      reviewId: string,
      assignedUsers: string,
      reason: string
    ): Promise<boolean> => {
      const result = await reviewComment({
        assigned: assignedUsers,
        objectId,
        review: ActionType[ActionType.INCORRECT],
        reviewId,
        reason,
      });

      if (result.success) {
        updateLocalState(objectId, {
          Actions: ActionType.INCORRECT,
          assigned: assignedUsers,
        });
      } else {
        handleError(result.message);
      }

      return Promise.resolve(result.success);
    },
    [updateLocalState]
  );

  const assignUsers = useCallback(
    async (
      objectId: string,
      reviewId: string,
      assignedUsers: string
    ): Promise<boolean> => {
      const result = await handleApiRequest<AssignUsersResult>(
        `assign/${objectId}`,
        'POST',
        { assigned: assignedUsers, id: reviewId }
      );

      if (result.success) {
        updateLocalState(objectId, {
          assigned: assignedUsers,
        });
      } else {
        handleError(result.message);
      }

      return Promise.resolve(result.success);
    },
    [updateLocalState]
  );

  const escalateReview = useCallback(
    async (
      objectId: string,
      reviewId: string,
      message: string
    ): Promise<boolean> => {
      const result = await handleApiRequest<AssignUsersResult>(
        `review/escalate/${objectId}`,
        'POST',
        { id: reviewId, message }
      );

      if (!result.success) {
        handleError(result.message);
      }

      return Promise.resolve(result.success);
    },
    []
  );

  const setReviewCorrect = useCallback(
    async (
      objectId: string,
      reviewId: string,
      assignedUsers: string
    ): Promise<boolean> => {
      const result = await reviewComment({
        assigned: assignedUsers,
        objectId,
        review: ActionType[ActionType.CORRECT],
        reviewId,
      });

      if (result.success) {
        updateLocalState(objectId, {
          Actions: ActionType.CORRECT,
          assigned: assignedUsers,
        });
      } else {
        handleError(result.message);
      }

      return Promise.resolve(result.success);
    },
    [updateLocalState]
  );

  return {
    getReview,
    reviewIssue,
    assignUsers,
    escalateReview,
    setReviewCorrect,
    assignedToCurrentUserIfNotExists,
  };
};

export default useReview;
