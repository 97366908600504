import { Typography, typographyClasses } from '@mui/material';
import { styled } from '@mui/system';
import classNames from 'classnames';
import useConfig from 'hooks/useConfig';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Buttons, ButtonStyled, CloseButton } from '../../CommonStyles';
import { ReviewIssueProps } from './models';

const ReviewIssueContainer = styled('div')(({ theme: { palette } }) => ({
  [`.${typographyClasses.h6}`]: {
    fontSize: '1.2rem',
    color: palette.text.secondary,
    fontWeight: 'bold',
    lineHeight: '1.6rem',
  },
}));

const CloseButtonContainer = styled('div')(() => ({
  textAlign: 'right',
  marginBottom: -10,
  marginTop: 10,
}));

const ReviewIssue: FC<ReviewIssueProps> = ({
  handleReviewIssue,
  setReviewIssueActive,
  reviewRaw,
}) => {
  const { t } = useTranslation();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { predictions } = useConfig();

  const onReviewIssueClick = async (reason: string) => {
    setButtonsDisabled(true);
    const result = await handleReviewIssue(reason);

    if (!result) {
      setButtonsDisabled(false);
    }
  };

  return (
    <ReviewIssueContainer>
      <Typography variant="h6">{t('whatsIssue')}</Typography>
      <Buttons>
        {predictions.map((reason) => (
          <ButtonStyled
            key={reason}
            className={classNames({
              active: reviewRaw?.validation === reason,
            })}
            color="info"
            variant="outlined"
            disabled={buttonsDisabled}
            aria-label={t(reason)}
            onClick={() => onReviewIssueClick(reason)}
          >
            {t(reason)}
          </ButtonStyled>
        ))}
      </Buttons>
      <CloseButtonContainer>
        <CloseButton
          variant="contained"
          disabled={buttonsDisabled}
          onClick={() => setReviewIssueActive(false)}
          aria-label={t('cancel')}
        >
          {t('cancel')}
        </CloseButton>
      </CloseButtonContainer>
    </ReviewIssueContainer>
  );
};

export default ReviewIssue;
