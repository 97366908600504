/* eslint-disable camelcase */
import SelectList from 'components/common/SelectList';
import {
  Actions,
  Collapsable,
  Rating,
  Url,
  Validation,
} from 'components/common/Table/Cell';
import { Prediction } from 'components/common/Table/Cell/Prediction';
import DatesFilter from 'components/TableFilters/DatesFilter';
import RatingsFilter from 'components/TableFilters/RatingsFilter';
import { convertTimestampToDate } from 'helpers/dateConverting';
import {
  ActionType,
  ModalColumnPosition,
  SortOrder,
  TableConfig,
} from 'types/tableConfig';

import RATING_ITEMS_CONFIG from './filters';

const ALGOLIA_INDEX_PREFIX = process.env.REACT_APP_ALGOLIA_INDEX;

export const DEFAULT_TABLE_CONFIG: TableConfig = {
  review_timestamp: {
    visible: true,
    order: 1,
    width: '100px',
    valueFn: ({ review_timestamp }) => convertTimestampToDate(review_timestamp),
    defaultSortDirection: SortOrder.DESC,
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_reviewDate`,
    filter: {
      component: DatesFilter,
      order: 2,
    },
    modal: {
      order: 1,
      position: ModalColumnPosition.TOP,
    },
  },
  assigned: {
    visible: true,
    order: 2,
    width: '115px',
    modal: {
      order: 16,
      position: ModalColumnPosition.BOTTOM,
    },
  },
  market: {
    visible: true,
    order: 3,
    customClasses: 'bold',
    width: '90px',
    filter: {
      component: SelectList,
      order: 1,
    },
    modal: {
      order: 2,
      position: ModalColumnPosition.TOP,
    },
  },
  brand: {
    visible: true,
    order: 4,
    customClasses: 'bold',
    width: '105px',
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_brand`,
    modal: {
      order: 4,
      position: ModalColumnPosition.TOP,
    },
    filter: {
      component: SelectList,
      order: 3,
    },
  },
  online_store: {
    visible: true,
    order: 5,
    width: '145px',
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_onlineStore`,
    modal: {
      order: 5,
      position: ModalColumnPosition.TOP,
    },
    filter: {
      component: SelectList,
      order: 4,
    },
  },
  category: {
    visible: true,
    order: 6,
    customClasses: 'bold',
    width: '115px',
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_category`,
    modal: {
      order: 6,
      position: ModalColumnPosition.TOP,
    },
    filter: {
      component: SelectList,
      order: 5,
    },
  },
  product_description: {
    visible: true,
    order: 7,
    customRenderer: Collapsable,
    width: '250px',
    modal: {
      order: 12,
      position: ModalColumnPosition.BOTTOM,
    },
  },
  review_title: {
    visible: true,
    order: 8,
    width: '130px',
    customClasses: 'bold',
    modal: {
      order: 13,
      position: ModalColumnPosition.BOTTOM,
    },
  },
  review: {
    visible: false,
    order: 9,
    customRenderer: Collapsable,
    width: '315px',
    modal: {
      order: 15,
      position: ModalColumnPosition.BOTTOM,
    },
  },
  original_review: {
    visible: true,
    order: 10,
    customRenderer: Collapsable,
    width: '315px',
    modal: {
      order: 14,
      position: ModalColumnPosition.BOTTOM,
    },
  },
  review_rating: {
    visible: true,
    order: 11,
    customRenderer: Rating,
    width: '120px',
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_rating`,
    modal: {
      order: 11,
      position: ModalColumnPosition.BOTTOM,
    },
    filter: {
      component: RatingsFilter,
      order: 6,
      items: RATING_ITEMS_CONFIG,
    },
  },
  retailer_product_code: {
    visible: true,
    order: 12,
    width: '130px',
    modal: {
      order: 3,
      position: ModalColumnPosition.TOP,
    },
    valueFn: ({ retailer_product_code }) => retailer_product_code,
  },
  source: {
    visible: true,
    order: 13,
    width: '115px',
    modal: {
      order: 7,
      position: ModalColumnPosition.TOP,
    },
  },
  prediction: {
    visible: true,
    order: 14,
    customRenderer: Prediction,
    width: '115px',
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_prediction`,
    modal: {
      order: 8,
      position: ModalColumnPosition.TOP,
    },
    valueFn: ({ validation, prediction }) => [prediction, validation],
  },
  url: {
    visible: true,
    order: 15,
    customRenderer: Url,
    width: '60px',
    modal: {
      order: 9,
      position: ModalColumnPosition.TOP,
    },
  },
  max_probability: {
    visible: true,
    order: 16,
    valueFn: ({ probability }) => probability.toString(),
    customRenderer: Validation,
    width: '115px',
    sortReplicaIndice: `${ALGOLIA_INDEX_PREFIX}_probability`,
    modal: {
      order: 10,
      position: ModalColumnPosition.TOP,
    },
  },
  Actions: {
    visible: true,
    order: 17,
    valueFn: ({ validation, prediction }) => {
      if (!validation) {
        return ActionType.NONE;
      }

      return validation === prediction
        ? ActionType.CORRECT
        : ActionType.INCORRECT;
    },
    customRenderer: Actions,
    width: '215px',
    customClasses: 'sticky-right',
    modal: {
      order: 0,
      position: ModalColumnPosition.NONE,
    },
    tooltipTranslationKey: 'actionsTooltip',
  },
};
