import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipProps } from './models';

const StyledIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
  cursor: 'pointer',
  fontSize: '1.7rem',
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: '0 5px',
  marginTop: '-5px',
}));

const ToolTip: FC<TooltipProps> = ({ tooltipText }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={<span>{t(tooltipText)}</span>} arrow>
      <StyledIconButton size="small">
        <StyledIcon />
      </StyledIconButton>
    </Tooltip>
  );
};

export default ToolTip;
