import { ISSUE_REVIEW_REASONS } from 'constants/modal';
import useConfig from 'hooks/useConfig';
import { createContext, FC, useEffect, useMemo, useState } from 'react';

import { ConfigContextProviderProps, ConfigContextType } from './models';

export const defaultValue: ConfigContextType = {
  predictions: ISSUE_REVIEW_REASONS,
};

export const ConfigContext = createContext<ConfigContextType>(defaultValue);

const ConfigContextProvider: FC<ConfigContextProviderProps> = ({
  children,
  config,
}) => {
  const [state, setState] = useState<ConfigContextType>(defaultValue);
  const { getPredictions } = useConfig();

  useEffect(() => {
    const getInitialData = async () => {
      const predictions = await getPredictions();

      setState((currState) => ({
        ...currState,
        predictions: [...predictions],
      }));
    };

    getInitialData();
  }, [getPredictions]);

  const contextValue = useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  return (
    <ConfigContext.Provider value={config || contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
